import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobTasksPage"},[_c('SocketWatch',{attrs:{"tables":['manufactory']},on:{"loadData":_vm.loadData}}),_c(VTabs,{staticClass:"mobTasksPage_tabs",attrs:{"fixed-tabs":"","color":"rgb(224, 166, 119)","background-color":"#626b70"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_vm._l((_vm.tabs),function(item){return _c(VTab,{key:item.key},[_vm._v(" "+_vm._s(item.text)+" ")])})],2),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c(VTabItem,{key:item.key},[_c('Tasks',{attrs:{"tasks":_vm.tasks}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }